import axios from "axios";
import TopBar from "components/VitalMeasuringScreen/TopBar";
import { Flex } from "components/shared/Flex";
import VitalSignCard from "components/VitalResultCard/VitalSignCard";
import { API_PATH } from "constant/api/APISetting";
import { PROFILE_DATA, STATUS_KEY, VITALSIGN_DATE, VITALSIGN_KEY, VitalElementArray, rangeCheckingVitalSign } from "constant/setting/VitalConstraint";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProgressBar from "components/VitalResultCard/ProgressBar";
import BottomBar from "components/shared/BottomBar";
import Helper from "helpers/Helper";
import { FLAG_VALUE, SOURCE_FLAG } from "constant/env/EnvKey";
import IsabelResult from '../../components/IsabelResult/IsabelResult';
import { IsabelDescription } from '../../constant/setting/IsabelDescription';
import parse from "html-react-parser";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const Wrapper = styled(Flex)`
  font-family: Rubik,Segoe UI,sans-serif;
  height: 100%;
  width: 100%;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
`;

const Bottom = styled(Flex)`
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Middle = styled(Flex)`
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: space-between;
  width: 25.5rem;

  @media (max-width: 480px){
    width: 95%;
  }

  @media (max-width: 320px){
    width: 90%;
  }
`;

const CardWrapper = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 30rem;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const TitleH1 = styled(Flex)`
  margin-top: 0.5rem;
  width: 25.5rem;
  height: 6%;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.7vh;
  font-weight: 700;
  align-self: center;
  justify-self: center;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
`;

const TitleH2 = styled(Flex)`
  width: 25.5rem;
  font-size: 2.7vh;
  font-weight: 700;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
`;

const TitleH3 = styled.div`
  margin: 5px 0 0 0;
  width: 25.5rem;
  font-size: 1.75vh;
  /* padding-left: 10px;  */
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: flex-start;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
`;

const TitleH4 = styled(Flex)`
  margin-top: 30px;
  font-size: 1.75vh;
  font-weight: 500;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: flex-start;
  width: 25.5rem;
  @media (max-width: 480px){
    width: 95%;
    margin-top: 25px;
  }
  @media (max-width: 320px){
    width: 90%;
    margin-top: 15px;
  }
  @media (max-width: 240px){
    margin-top: 5px;
  }
`;

const Paragraph = styled(Flex)`
  font-size: 1.5vh;
  align-self: start;
  align-items: center;
  justify-self: start;
  justify-content: flex-start;
`;

const Recommendation = styled(Flex)`
  /* margin-top: 5px; */
  font-size: 1.3vh;
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 90%;
  font-style: italic;
  color: #7a8395;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
  @media (max-width: 240px){
    font-size: 0.4rem;
  }
`;

const Time = styled(Flex)`
`;

const Day = styled(Flex)`
`;

const TimeDay = styled(Flex)`
  font-size: 0.85rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  height: 4%;
  color: #7a8395;
  gap: 5px;
  @media (max-width: 320px){
    font-size: 0.6rem;
  }
  @media (max-width: 240px){
    font-size: 0.48rem;
  }
`;

const ProfileDetail = styled(Flex)`
  width: 10.5rem;
  align-self: flex-start;
  padding: 10px;
  gap: 10px;
  flex-direction: column;
  @media (max-width: 320px){
    padding: 15px 10px 10px;
  }
  @media (max-width: 240px){
    gap: 5px;
  }
`;

const Information = styled(Flex)`
  font-size: 1.05rem;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 480px){
    font-size: 1rem;
  }
  @media (max-width: 320px){
    font-size: 0.68rem;
  }
  @media (max-width: 240px){
    font-size: 0.58rem;
  }
`;
const IsabelWrapper = styled(Flex)`
  width: 25rem;
  justify-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0 0 0;
  @media (max-width: 480px){
    width: 100%;
  }
`;

const IsabelBox = styled(Flex)`
  height: fit-content;
  width: 100%;
  margin-top: 0.625rem;
  padding: 0.625rem 0.25rem;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 12px;
  border-radius: 10px;

  @media (max-width: 480px){
    width: 95%;
  }
  
  @media (max-width: 375px){
  }

  @media (max-width: 320px){
  }
  
  @media (max-width: 240px){
    margin: 0.156rem;
  }
`;

const ParagraphWrapper = styled(Flex)`
  color: #5e5e5e;
  width: 25.5rem;
  @media (max-width: 480px){
    width: 95%;
  }
  @media (max-width: 320px){
    width: 90%;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{
        overflow: "auto",
        width: "100%",
      }}
      {...other}
    >
      {value === index && (
        <div style={{
          paddingTop: 16,
        }}>
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ContentWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px){
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`;

export default function Review_Callflow() {
  const [result, setResult] = useState();
  const [wellness, setWellness] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [datetime, setDatetime] = useState({});
  const [isabelConditions, setIsabelConditions] = useState({});

  useEffect(() => {
    const getProfileData = async () => {
      const token = localStorage.getItem("token");
      var dataResponse = null;
      if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
        dataResponse = await axios.get(`${API_PATH.getKeyPairValueRPM}/${token}`);
      } else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
        dataResponse = await axios.get(`${API_PATH.getKeyPairValue}/${token}`);
      }
      var VitalsHistoryValue = await JSON.parse(dataResponse?.data?.result);
      setIsabelConditions(JSON.parse(decodeURIComponent(VitalsHistoryValue.conditions)));
      if (VitalsHistoryValue) {
        const objConverted = Helper.convertJsonToLowercase(VitalsHistoryValue);
        var userDataObj = {};
        userDataObj[PROFILE_DATA.Gender] = objConverted[PROFILE_DATA.Gender];
        userDataObj[PROFILE_DATA.Age] = objConverted[PROFILE_DATA.Age];
        userDataObj[PROFILE_DATA.Pregnant] = objConverted[PROFILE_DATA.Pregnant];
        userDataObj[PROFILE_DATA.Symptoms] = objConverted[PROFILE_DATA.Symptoms]
        setProfileData(userDataObj);

        setDatetime(VitalsHistoryValue[VITALSIGN_DATE.DateTime])

        // resolve data get the key and value
        var greenObj: any = {};
        var redObj: any = {};
        var unknownObj: any = {};
        var tempObj: any = {};
        var age: number;
        if (Object.keys(profileData).length != 0) {
          age = parseInt(userDataObj[PROFILE_DATA.Age]);
        } else {
          age = null;
        }
        Object.entries(VitalsHistoryValue).map((item) => {
          if (VitalElementArray.includes(item[0])) {
            if (item[0] !== VITALSIGN_KEY.WellnessRange) {
              if (age) {
                if (rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.NORMAL || rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.MEDIUM) {
                  greenObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.HIGH || rangeCheckingVitalSign(item[0], item[1], age) == STATUS_KEY.LOW) {
                  redObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.UNKNOWN) {
                  unknownObj[`${item[0]}`] = item[1];
                }
              } else {
                if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.NORMAL || rangeCheckingVitalSign(item[0], item[1], age) === STATUS_KEY.MEDIUM) {
                  greenObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.HIGH || rangeCheckingVitalSign(item[0], item[1]) == STATUS_KEY.LOW) {
                  redObj[`${item[0]}`] = item[1];
                } else if (rangeCheckingVitalSign(item[0], item[1]) === STATUS_KEY.UNKNOWN) {
                  unknownObj[`${item[0]}`] = item[1];
                }
              }
            } else {
              var wellnessObj = [];
              wellnessObj.push(item[1]);
              wellnessObj.push(rangeCheckingVitalSign(item[0], item[1]));
              setWellness(wellnessObj);
            }
          }
        });
        tempObj = { ...redObj, ...greenObj, ...unknownObj };
        setResult(tempObj);
      } else {
        localStorage.setItem("profileData", null);
      }
    };
    getProfileData();
  }, []);


  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <TopBar onSettingsClick={""} isMeasuring={false} isLoading={false} isVideoReady={false} handleButtonClick={false} isShown={false} />
      <Wrapper>
        <TitleH1>Wellness Overall Status</TitleH1>
        <Middle>
          <ProgressBar wellnessRange={Number.parseInt(wellness[0])} wellnessRangeStatus={wellness[1]} />
          <ProfileDetail>
            <TimeDay>
              <Time>{datetime ? new Date(datetime.toString()).toLocaleTimeString() : "time"}</Time><span> • </span>
              <Day>{datetime ? new Date(datetime.toString()).toLocaleDateString() : "date"}</Day>
            </TimeDay>
            {Object.keys(profileData).length !== 0 &&
              <>
                <Information>{`Sex: ${profileData[PROFILE_DATA.Gender] ? Helper.displayGender(profileData[PROFILE_DATA.Gender]) : ""}`}</Information>
                <Information>{`Age: ${profileData[PROFILE_DATA.Age] ? profileData[PROFILE_DATA.Age] : ""}`}</Information>
                <Information>{`Pregnant: ${profileData[PROFILE_DATA.Pregnant] ? Helper.displayPregnant(profileData[PROFILE_DATA.Pregnant]) : ""}`}</Information>
              </>
            }
          </ProfileDetail>
        </Middle>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          TabIndicatorProps={{ style: { background: "#00D681", color: "#00D681 !important", fontWeight: 600 } }}
          sx={{
            position: "relative",
            width: "25rem",
            "@media (max-width: 500px)": {
              width: "100%",
              fontSize: "1rem"
            },
          }}
          aria-label="review screen tab"
        >
          <Tab label="Vital Result" {...a11yProps(0)} sx={{
            fontWeight: 600,
            fontSize: "0.8rem",
            '&.Mui-selected': {
              color: '#00D681',
            },
          }} />
          <Tab label="Isabel Result" {...a11yProps(1)} sx={{
            fontWeight: 600,
            fontSize: "0.8rem",
            '&.Mui-selected': {
              color: '#00D681',
            },
          }} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <ContentWrapper>
            {/* <TitleH2>Vital Signs Result</TitleH2> */}
            <Bottom>
              <CardWrapper>{result && Object.entries(result).map((item, index) => <VitalSignCard key={index} vitalSign={item[0]} data={item[1]} />)}</CardWrapper>
            </Bottom>
          </ContentWrapper>
          <BottomBar />
        </TabPanel>

        <TabPanel value={value} index={1}>
       
            <ContentWrapper>
            
              <TitleH3><span style={{ fontWeight: 700 }}>Symptoms:</span> {profileData["symptoms"]}</TitleH3>
              <IsabelWrapper>
                <IsabelBox>
                  {
                    Object.entries(isabelConditions).map((item, index) =>
                      <IsabelResult key={index} condition={item[1]} />
                    )
                  }
                  <Recommendation>{IsabelDescription.Recommendations}</Recommendation>
                </IsabelBox>
                <TitleH4>{IsabelDescription.ResultDetailTitle}</TitleH4>
                <ParagraphWrapper>
                  <Paragraph>{parse(IsabelDescription.ResultDetail)}</Paragraph>
                </ParagraphWrapper>
              </IsabelWrapper>
            </ContentWrapper>
 
          <BottomBar />
        </TabPanel>
      </Wrapper>
    </>
  );
}