import { HealthMonitorCodes, SessionState } from "@binah/web-sdk";
import { useError, useLicenseKey, useMeasurementDuration, useMonitor, usePageVisibility, usePrevious, useWarning } from "hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import media from "style/media";
import { mirror } from "style/mirror";
import styled from "styled-components";
import { VideoReadyState } from "types";
import { ErrorAlert, InfoAlert, WarningAlert } from "../alert";
import Loader from "./Loader";
import { Flex } from "../shared/Flex";
import Mask from "../../assets/primas/primas-mask-9.svg";
import TopBar from "./TopBar";
import MeasurePopup from "components/VitalMeasurePopup/MeasurePopup";
import Helper from "helpers/Helper";
import axios from "axios";
import { API_PATH } from "constant/api/APISetting";
import { useLocation, useNavigate } from "react-router-dom";
import { FLAG_VALUE, SOURCE_FLAG } from "constant/env/EnvKey";


const MonitorWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  overflow: hidden;
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  flex: 7.55;
  height: 90%;
`;

const VideoAndStatsWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: inherit;
  z-index: -1;
`;

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  ${media.desktop`
  `}
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${media.desktop`
  `}
  ${mirror}
`;

export default function PrimasMonitor({ showMonitor, cameraId, onLicenseStatus, onSettingsClick }) {
  const video = useRef<HTMLVideoElement>(null);
  const isMediaTablet = useMediaPredicate("(min-width: 1920px)");
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState<boolean>(false);
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
  const isPageVisible = usePageVisibility();
  const [processingTime] = useMeasurementDuration();
  const [licenseKey] = useLicenseKey();
  const { sessionState, vitalSigns, offlineMeasurements, error, warning, info } = useMonitor(video, cameraId, processingTime, licenseKey, null, startMeasuring);
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);

  const isMeasuring = useCallback(() => sessionState === SessionState.MEASURING, [sessionState]);

  const isVideoReady = useCallback(() => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA, []);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const navigate = useNavigate();
  const handleButtonClick = useCallback(() => {
    if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
      //if testmode == true
      if (localStorage.getItem(process.env.REACT_APP_BYPASS_CHECKING_KEY) == process.env.REACT_APP_BYPASS_CHECKING_VALUE) {
        async function callAPI() {
          const dataSending: {} = {
            ID: `3;${localStorage.getItem(process.env.REACT_APP_BYPASS_KEY)}`,
            KeyPair: Helper.modifiedRequestData(Helper.randomVitalSignData),
          };
          const res = await axios.post(
            `${API_PATH.addKeyPairValue}`,
            dataSending
          );
          return res;
        }

        try {
          callAPI();
          localStorage.setItem("refresh", "0");
          navigate(`/vitalsign/review?token=${token}`, { state: Helper.randomVitalSignData });
        } catch (err) {
          console.log(err);
        }
      };
    }

    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
      setLoadingTimeoutPromise(window.setTimeout(() => setIsLoading(true), processingTime * 1000));
    } else if (isMeasuring()) {
      clearTimeout(loadingTimeoutPromise);
      setStartMeasuring(false);
    }
  }, [sessionState, setIsLoading, processingTime]);

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      if (errorMessage) {
        setIsMeasurementEnabled(false);
      } else {
        setIsMeasurementEnabled(true);
      }
      !isPageVisible && setStartMeasuring(false);
    } else if ((sessionState === SessionState.ACTIVE || sessionState === SessionState.TERMINATED) && errorMessage) {
      setIsMeasurementEnabled(false);
    }
    if (sessionState === SessionState.ACTIVE && prevSessionState !== sessionState) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [errorMessage, sessionState, isPageVisible]);

  useEffect(() => {
    onLicenseStatus(!(error?.code in HealthMonitorCodes));
  }, [error]);

  if (!showMonitor) {
    return null;
  }

  return (
    <>
      <TopBar onSettingsClick={onSettingsClick} isMeasuring={isMeasuring()} isLoading={isLoading} isVideoReady={isVideoReady()} handleButtonClick={handleButtonClick} isShown={true} />
      <MonitorWrapper>
        <MeasurementContentWrapper>
          <VideoAndStatsWrapper>
            <VideoWrapper>
              <Img src={Mask} />
              <Video ref={video} id="video" muted={true} playsInline={true} />
            </VideoWrapper>
            {isMeasuring() && <InfoAlert message={info.message} />}
            {isMeasuring() && <WarningAlert message={warningMessage} />}
            {isMeasuring() && <ErrorAlert message={errorMessage} />}
            <MeasurePopup isMeasuring={isMeasuring()} durationSeconds={processingTime} vitalSigns={vitalSigns} />
            {!isVideoReady() && licenseKey && <Loader />}
          </VideoAndStatsWrapper>
        </MeasurementContentWrapper>
      </MonitorWrapper>
    </>
  );
}